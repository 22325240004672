/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getStudentMeta = /* GraphQL */ `
  query GetStudentMeta($id: ID!) {
    getStudentMeta(id: $id) {
      id
      cognitoUsername
      name
      email
      tel
      studentClassType
      studentMetaStudentClassId
      studentClassId
      priority
      disabled
      createdAt
      updatedAt
      deletedAt
      clientNr
    }
  }
`;
export const listStudentMetas = /* GraphQL */ `
  query ListStudentMetas(
    $filter: ModelStudentMetaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentMetas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoUsername
        name
        email
        tel
        studentClassType
        studentMetaStudentClassId
        studentClassId
        priority
        disabled
        createdAt
        updatedAt
        deletedAt
        clientNr
      }
      nextToken
    }
  }
`;
export const getTeacherMeta = /* GraphQL */ `
  query GetTeacherMeta($id: ID!) {
    getTeacherMeta(id: $id) {
      id
      cognitoUsername
      name
      email
      tel
      color
      disabled
      createdAt
      updatedAt
      deletedAt
      hasAccessToLocations
    }
  }
`;
export const listTeacherMetas = /* GraphQL */ `
  query ListTeacherMetas(
    $filter: ModelTeacherMetaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeacherMetas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoUsername
        name
        email
        tel
        color
        disabled
        createdAt
        updatedAt
        deletedAt
        hasAccessToLocations
      }
      nextToken
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      title
      description
      routeDescription
      extraDescriptionFile {
        fileKey
      }
      gallery {
        fileKey
      }
      tel
      email
      place
      street
      number
      website
      postalCode
      createdAt
      updatedAt
      deletedAt
      locationDefaultTeacherId
      locationTeacherMetaId
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        routeDescription
        extraDescriptionFile {
          fileKey
        }
        gallery {
          fileKey
        }
        tel
        email
        place
        street
        number
        website
        postalCode
        createdAt
        updatedAt
        deletedAt
        locationDefaultTeacherId
        locationTeacherMetaId
      }
      nextToken
    }
  }
`;
export const getStudentClass = /* GraphQL */ `
  query GetStudentClass($id: ID!) {
    getStudentClass(id: $id) {
      id
      title
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const listStudentClasss = /* GraphQL */ `
  query ListStudentClasss(
    $filter: ModelStudentClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentClasss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const getLesson = /* GraphQL */ `
  query GetLesson($id: ID!) {
    getLesson(id: $id) {
      id
      title
      lessonLessonGroupId
      weekNr
      description
      createdAt
      updatedAt
      deletedAt
      registrationLimit
      duration
      deadlineAt
      openAt
      priorityAt
      studentClassMayRegister
      gallery {
        fileKey
      }
    }
  }
`;
export const listLessons = /* GraphQL */ `
  query ListLessons(
    $filter: ModelLessonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLessons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        lessonLessonGroupId
        weekNr
        description
        createdAt
        updatedAt
        deletedAt
        registrationLimit
        duration
        deadlineAt
        openAt
        priorityAt
        studentClassMayRegister
        gallery {
          fileKey
        }
      }
      nextToken
    }
  }
`;
export const getLessonGroupStudentClass = /* GraphQL */ `
  query GetLessonGroupStudentClass($id: ID!) {
    getLessonGroupStudentClass(id: $id) {
      id
      lessonGroupStudentClassStudentClassId
      lessonGroupStudentClassLessonGroupId
      createdAt
      updatedAt
    }
  }
`;
export const listLessonGroupStudentClasss = /* GraphQL */ `
  query ListLessonGroupStudentClasss(
    $filter: ModelLessonGroupStudentClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLessonGroupStudentClasss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lessonGroupStudentClassStudentClassId
        lessonGroupStudentClassLessonGroupId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLessonGroup = /* GraphQL */ `
  query GetLessonGroup($id: ID!) {
    getLessonGroup(id: $id) {
      id
      createdAt
      updatedAt
      openAt
      deadlineAt
      priorityAt
      title
      description
      deletedAt
      price
      products {
        title
        description
        tax
        priceEx
      }
    }
  }
`;
export const listLessonGroups = /* GraphQL */ `
  query ListLessonGroups(
    $filter: ModelLessonGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLessonGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        openAt
        deadlineAt
        priorityAt
        title
        description
        deletedAt
        price
        products {
          title
          description
          tax
          priceEx
        }
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      monthYear
      title
      registrationLimit
      beginsAt
      endsAt
      createdAt
      updatedAt
      deletedAt
      eventLessonId
      eventLocationId
      eventTeacherMetaId
      studentClassMayRegister
      status
      eventLessonTeacherMetaLocationId
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        monthYear
        title
        registrationLimit
        beginsAt
        endsAt
        createdAt
        updatedAt
        deletedAt
        eventLessonId
        eventLocationId
        eventTeacherMetaId
        studentClassMayRegister
        status
        eventLessonTeacherMetaLocationId
      }
      nextToken
    }
  }
`;
export const getEventRegistration = /* GraphQL */ `
  query GetEventRegistration($id: ID!) {
    getEventRegistration(id: $id) {
      id
      cognitoUsername
      present
      comment
      skill
      eventRegistrationStudentMetaId
      eventRegistrationEventId
      createdAt
      updatedAt
      deletedAt
      paidStatus
      tikkieLink
      factuurNr
    }
  }
`;
export const listEventRegistrations = /* GraphQL */ `
  query ListEventRegistrations(
    $filter: ModelEventRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventRegistrations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUsername
        present
        comment
        skill
        eventRegistrationStudentMetaId
        eventRegistrationEventId
        createdAt
        updatedAt
        deletedAt
        paidStatus
        tikkieLink
        factuurNr
      }
      nextToken
    }
  }
`;
export const getLessonTeacherMetaLocation = /* GraphQL */ `
  query GetLessonTeacherMetaLocation($id: ID!) {
    getLessonTeacherMetaLocation(id: $id) {
      id
      lessonTeacherMetaLocationLessonId
      lessonTeacherMetaLocationTeacherMetaId
      lessonTeacherMetaLocationLocationId
      schedulePermission {
        startsAt
        endsAt
        eventLimit
      }
      createdAt
      updatedAt
    }
  }
`;
export const listLessonTeacherMetaLocations = /* GraphQL */ `
  query ListLessonTeacherMetaLocations(
    $filter: ModelLessonTeacherMetaLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLessonTeacherMetaLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lessonTeacherMetaLocationLessonId
        lessonTeacherMetaLocationTeacherMetaId
        lessonTeacherMetaLocationLocationId
        schedulePermission {
          startsAt
          endsAt
          eventLimit
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const studentMetaByCognitoUsername = /* GraphQL */ `
  query StudentMetaByCognitoUsername(
    $cognitoUsername: String
    $sortDirection: ModelSortDirection
    $filter: ModelStudentMetaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studentMetaByCognitoUsername(
      cognitoUsername: $cognitoUsername
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUsername
        name
        email
        tel
        studentClassType
        studentMetaStudentClassId
        studentClassId
        priority
        disabled
        createdAt
        updatedAt
        deletedAt
        clientNr
      }
      nextToken
    }
  }
`;
export const teacherMetaByCognitoUsername = /* GraphQL */ `
  query TeacherMetaByCognitoUsername(
    $cognitoUsername: String
    $sortDirection: ModelSortDirection
    $filter: ModelTeacherMetaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teacherMetaByCognitoUsername(
      cognitoUsername: $cognitoUsername
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUsername
        name
        email
        tel
        color
        disabled
        createdAt
        updatedAt
        deletedAt
        hasAccessToLocations
      }
      nextToken
    }
  }
`;
export const lessonByLessonGroupId = /* GraphQL */ `
  query LessonByLessonGroupId(
    $lessonLessonGroupId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLessonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lessonByLessonGroupId(
      lessonLessonGroupId: $lessonLessonGroupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        lessonLessonGroupId
        weekNr
        description
        createdAt
        updatedAt
        deletedAt
        registrationLimit
        duration
        deadlineAt
        openAt
        priorityAt
        studentClassMayRegister
        gallery {
          fileKey
        }
      }
      nextToken
    }
  }
`;
export const lessonGroupStudentClassByLessonGroupId = /* GraphQL */ `
  query LessonGroupStudentClassByLessonGroupId(
    $lessonGroupStudentClassLessonGroupId: ID
    $lessonGroupStudentClassStudentClassId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLessonGroupStudentClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lessonGroupStudentClassByLessonGroupId(
      lessonGroupStudentClassLessonGroupId: $lessonGroupStudentClassLessonGroupId
      lessonGroupStudentClassStudentClassId: $lessonGroupStudentClassStudentClassId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lessonGroupStudentClassStudentClassId
        lessonGroupStudentClassLessonGroupId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const lessonGroupStudentClassByStudentClassId = /* GraphQL */ `
  query LessonGroupStudentClassByStudentClassId(
    $lessonGroupStudentClassStudentClassId: ID
    $lessonGroupStudentClassLessonGroupId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLessonGroupStudentClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lessonGroupStudentClassByStudentClassId(
      lessonGroupStudentClassStudentClassId: $lessonGroupStudentClassStudentClassId
      lessonGroupStudentClassLessonGroupId: $lessonGroupStudentClassLessonGroupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lessonGroupStudentClassStudentClassId
        lessonGroupStudentClassLessonGroupId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const eventByLessonId = /* GraphQL */ `
  query EventByLessonId(
    $eventLessonId: ID
    $monthYear: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventByLessonId(
      eventLessonId: $eventLessonId
      monthYear: $monthYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        monthYear
        title
        registrationLimit
        beginsAt
        endsAt
        createdAt
        updatedAt
        deletedAt
        eventLessonId
        eventLocationId
        eventTeacherMetaId
        studentClassMayRegister
        status
        eventLessonTeacherMetaLocationId
      }
      nextToken
    }
  }
`;
export const eventByLocationId = /* GraphQL */ `
  query EventByLocationId(
    $eventLocationId: ID
    $monthYear: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventByLocationId(
      eventLocationId: $eventLocationId
      monthYear: $monthYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        monthYear
        title
        registrationLimit
        beginsAt
        endsAt
        createdAt
        updatedAt
        deletedAt
        eventLessonId
        eventLocationId
        eventTeacherMetaId
        studentClassMayRegister
        status
        eventLessonTeacherMetaLocationId
      }
      nextToken
    }
  }
`;
export const eventByTeacherMetaId = /* GraphQL */ `
  query EventByTeacherMetaId(
    $eventTeacherMetaId: ID
    $monthYear: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventByTeacherMetaId(
      eventTeacherMetaId: $eventTeacherMetaId
      monthYear: $monthYear
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        monthYear
        title
        registrationLimit
        beginsAt
        endsAt
        createdAt
        updatedAt
        deletedAt
        eventLessonId
        eventLocationId
        eventTeacherMetaId
        studentClassMayRegister
        status
        eventLessonTeacherMetaLocationId
      }
      nextToken
    }
  }
`;
export const eventByMonthYear = /* GraphQL */ `
  query EventByMonthYear(
    $monthYear: String
    $beginsAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventByMonthYear(
      monthYear: $monthYear
      beginsAt: $beginsAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        monthYear
        title
        registrationLimit
        beginsAt
        endsAt
        createdAt
        updatedAt
        deletedAt
        eventLessonId
        eventLocationId
        eventTeacherMetaId
        studentClassMayRegister
        status
        eventLessonTeacherMetaLocationId
      }
      nextToken
    }
  }
`;
export const eventByLessonTeacherMetaLocation = /* GraphQL */ `
  query EventByLessonTeacherMetaLocation(
    $eventLessonTeacherMetaLocationId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventByLessonTeacherMetaLocation(
      eventLessonTeacherMetaLocationId: $eventLessonTeacherMetaLocationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        monthYear
        title
        registrationLimit
        beginsAt
        endsAt
        createdAt
        updatedAt
        deletedAt
        eventLessonId
        eventLocationId
        eventTeacherMetaId
        studentClassMayRegister
        status
        eventLessonTeacherMetaLocationId
      }
      nextToken
    }
  }
`;
export const eventRegistrationByStudentMetaId = /* GraphQL */ `
  query EventRegistrationByStudentMetaId(
    $eventRegistrationStudentMetaId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEventRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventRegistrationByStudentMetaId(
      eventRegistrationStudentMetaId: $eventRegistrationStudentMetaId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUsername
        present
        comment
        skill
        eventRegistrationStudentMetaId
        eventRegistrationEventId
        createdAt
        updatedAt
        deletedAt
        paidStatus
        tikkieLink
        factuurNr
      }
      nextToken
    }
  }
`;
export const eventRegistrationByEventId = /* GraphQL */ `
  query EventRegistrationByEventId(
    $eventRegistrationEventId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEventRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventRegistrationByEventId(
      eventRegistrationEventId: $eventRegistrationEventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUsername
        present
        comment
        skill
        eventRegistrationStudentMetaId
        eventRegistrationEventId
        createdAt
        updatedAt
        deletedAt
        paidStatus
        tikkieLink
        factuurNr
      }
      nextToken
    }
  }
`;
export const lessonTeacherMetaLocationByLessonId = /* GraphQL */ `
  query LessonTeacherMetaLocationByLessonId(
    $lessonTeacherMetaLocationLessonId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLessonTeacherMetaLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lessonTeacherMetaLocationByLessonId(
      lessonTeacherMetaLocationLessonId: $lessonTeacherMetaLocationLessonId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lessonTeacherMetaLocationLessonId
        lessonTeacherMetaLocationTeacherMetaId
        lessonTeacherMetaLocationLocationId
        schedulePermission {
          startsAt
          endsAt
          eventLimit
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const lessonTeacherMetaLocationByTeacherMetaId = /* GraphQL */ `
  query LessonTeacherMetaLocationByTeacherMetaId(
    $lessonTeacherMetaLocationTeacherMetaId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLessonTeacherMetaLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lessonTeacherMetaLocationByTeacherMetaId(
      lessonTeacherMetaLocationTeacherMetaId: $lessonTeacherMetaLocationTeacherMetaId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lessonTeacherMetaLocationLessonId
        lessonTeacherMetaLocationTeacherMetaId
        lessonTeacherMetaLocationLocationId
        schedulePermission {
          startsAt
          endsAt
          eventLimit
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const lessonTeacherMetaLocationByLocationId = /* GraphQL */ `
  query LessonTeacherMetaLocationByLocationId(
    $lessonTeacherMetaLocationLocationId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLessonTeacherMetaLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lessonTeacherMetaLocationByLocationId(
      lessonTeacherMetaLocationLocationId: $lessonTeacherMetaLocationLocationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lessonTeacherMetaLocationLessonId
        lessonTeacherMetaLocationTeacherMetaId
        lessonTeacherMetaLocationLocationId
        schedulePermission {
          startsAt
          endsAt
          eventLimit
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
