import updateCacheAndState from "../model/updateCacheAndState";
import mutation from "./mutation";
import produce from "immer";

export const m_delete = async ({ item, typeName, set, get }) => {
    const result = await mutation(
        {
            mutationType: "delete",
            item,
            set, get, typeName,
        }
    );

    if (result.statusCode === 200) {
        updateCacheAndState({ item: { ...item }, mutationType: "delete", set, get, typeName });

        set(produce((state => {
            state.messages = [{
                message: "Successvol aangemaakt",
                variant: "success"
            }]
        })));

        return {
            ...result,
            item: {
                ...item,
            }
        }
    }else{
        set(produce((state => {
            state.messages = [{
                message: "Verwijderen mislukt",
                variant: "Error"
            }]
        })));
    }
    return result


}

export default m_delete;