import { firstCharToLowerCase } from "../utils/utils";
import query from "./query";

export const resolveConnections = (items, typeName, connections, fetchConnections, get) => {
    const promises = [];
    for (const item of items) {
        if (fetchConnections && Array.isArray(fetchConnections) && item) {
            for (const fetchConnection of fetchConnections) {
                const connectionObj = connections[fetchConnection.typeName];
                const forceRefetch = Boolean(fetchConnection.forceRefetch)
                if (connectionObj && (connectionObj.relation === "belongsToMany" || connectionObj.relation === "belongsTo" || connectionObj.relation === "hasOne")) {
                    let key = `${firstCharToLowerCase(typeName)}${fetchConnection.typeName}Id`;

                    if (item && item[key]) {
                        let operationName = "get" + fetchConnection.typeName;
                        const subConnections = fetchConnection.subConnections ? fetchConnection.subConnections : [];
                        promises.push(new Promise(async (resolve, reject) => {
                            try {
                                const result = await query("get", {
                                    id: item[key],
                                }, subConnections, forceRefetch, fetchConnection.operationName, get().models[fetchConnection.typeName].connections, fetchConnection.typeName, get);
                                resolve({
                                    item: result,
                                    key: firstCharToLowerCase(fetchConnection.typeName),
                                    belongsTo: item,
                                });
                            } catch (err) {
                                alert("OEI")
                            }
                        }))
                    }else{
                        // alert("NOT FOUND" + key)
                    }

                }

                if (connectionObj && connectionObj.relation === "hasMany") {


                    let operationName = `${firstCharToLowerCase(fetchConnection.typeName)}By${typeName}Id`;

                    let variables = {};
                    variables[`${firstCharToLowerCase(fetchConnection.typeName)}${typeName}Id`] = item.id;
                    const subConnections = fetchConnection.subConnections ? fetchConnection.subConnections : [];

                    promises.push(new Promise(async (resolve, reject) => {
                        const result = await query("list", variables, subConnections, forceRefetch, operationName, get().models[fetchConnection.typeName].connections, fetchConnection.typeName, get);
                        resolve({
                            item: result,
                            key: firstCharToLowerCase(fetchConnection.typeName) + "s",
                            belongsTo: item,
                        });

                    }))
                }
                if (connectionObj && connectionObj.relation === "hasManyThrough") {
                    let operationName = `${firstCharToLowerCase(fetchConnection.typeName)}By${typeName}Id`;

                    let variables = {};
                    variables[`${firstCharToLowerCase(fetchConnection.typeName)}${typeName}Id`] = item.id;
                    const subConnections = fetchConnection.subConnections ? fetchConnection.subConnections : [];

                    promises.push(new Promise(async (resolve, reject) => {
                        const result = await query("list", variables, subConnections, forceRefetch, operationName, get().models[fetchConnection.typeName].connections, fetchConnection.typeName, get);
                        resolve({
                            item: result,
                            key: firstCharToLowerCase(fetchConnection.typeName) + "s",
                            belongsTo: item,
                        });

                    }))
                }

            }
        }
    }
    return promises
}

export default resolveConnections;