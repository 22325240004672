import { Cache } from "aws-amplify";
import { parse, stringify } from 'flatted';
import produce from 'immer';
import { getCacheName, getQueryName } from "../utils/utils";
import { fetchItemsNextToken } from "./fetchItemsNextToken";

export const listAll = async (variables, fetchConnections, forceRefetch, typeName, set, get) => {

    set(produce((state => {
        state.models[typeName].loading = true
    })));
    let result = {};

    const newQueries = { ...get().models[typeName].queries };
    const newVariables = { ...variables };
    Reflect.deleteProperty(newVariables, "nextToken");

    const queryName = getQueryName("list", variables);

    newQueries[queryName] = {
        typeName: typeName,
        operationName: "list" + typeName + "s",
        variables: newVariables,
    }

    let cacheName = getCacheName(typeName, "list", variables);
    const cache = await Cache.getItem(cacheName);

    if (forceRefetch || !cache) {
        result = await fetchItemsNextToken({ variables: variables, allItems: [], fetchConnections: fetchConnections, forceRefetch, typeName, get });
        await Cache.removeItem(cacheName);

        try {
            await Cache.setItem(cacheName, stringify({
                items: result.items
            }));

        } catch (e) {
            alert("ERROR")
            console.error(e)
        }
    } else {
        result = parse(cache)
    }

    set(produce((state => {
        state.models[typeName].loading = false
        state.models[typeName].queries = newQueries
        state.models[typeName].items['listAll'] = result.items
    })));

    return result.items;
}

export default listAll;