/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createStudentMeta = /* GraphQL */ `
  mutation CreateStudentMeta(
    $input: CreateStudentMetaInput!
    $condition: ModelStudentMetaConditionInput
  ) {
    createStudentMeta(input: $input, condition: $condition) {
      id
      cognitoUsername
      name
      email
      tel
      studentClassType
      studentMetaStudentClassId
      studentClassId
      priority
      disabled
      createdAt
      updatedAt
      deletedAt
      clientNr
    }
  }
`;
export const updateStudentMeta = /* GraphQL */ `
  mutation UpdateStudentMeta(
    $input: UpdateStudentMetaInput!
    $condition: ModelStudentMetaConditionInput
  ) {
    updateStudentMeta(input: $input, condition: $condition) {
      id
      cognitoUsername
      name
      email
      tel
      studentClassType
      studentMetaStudentClassId
      studentClassId
      priority
      disabled
      createdAt
      updatedAt
      deletedAt
      clientNr
    }
  }
`;
export const deleteStudentMeta = /* GraphQL */ `
  mutation DeleteStudentMeta(
    $input: DeleteStudentMetaInput!
    $condition: ModelStudentMetaConditionInput
  ) {
    deleteStudentMeta(input: $input, condition: $condition) {
      id
      cognitoUsername
      name
      email
      tel
      studentClassType
      studentMetaStudentClassId
      studentClassId
      priority
      disabled
      createdAt
      updatedAt
      deletedAt
      clientNr
    }
  }
`;
export const createTeacherMeta = /* GraphQL */ `
  mutation CreateTeacherMeta(
    $input: CreateTeacherMetaInput!
    $condition: ModelTeacherMetaConditionInput
  ) {
    createTeacherMeta(input: $input, condition: $condition) {
      id
      cognitoUsername
      name
      email
      tel
      color
      disabled
      createdAt
      updatedAt
      deletedAt
      hasAccessToLocations
    }
  }
`;
export const updateTeacherMeta = /* GraphQL */ `
  mutation UpdateTeacherMeta(
    $input: UpdateTeacherMetaInput!
    $condition: ModelTeacherMetaConditionInput
  ) {
    updateTeacherMeta(input: $input, condition: $condition) {
      id
      cognitoUsername
      name
      email
      tel
      color
      disabled
      createdAt
      updatedAt
      deletedAt
      hasAccessToLocations
    }
  }
`;
export const deleteTeacherMeta = /* GraphQL */ `
  mutation DeleteTeacherMeta(
    $input: DeleteTeacherMetaInput!
    $condition: ModelTeacherMetaConditionInput
  ) {
    deleteTeacherMeta(input: $input, condition: $condition) {
      id
      cognitoUsername
      name
      email
      tel
      color
      disabled
      createdAt
      updatedAt
      deletedAt
      hasAccessToLocations
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      id
      title
      description
      routeDescription
      extraDescriptionFile {
        fileKey
      }
      gallery {
        fileKey
      }
      tel
      email
      place
      street
      number
      website
      postalCode
      createdAt
      updatedAt
      deletedAt
      locationDefaultTeacherId
      locationTeacherMetaId
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      id
      title
      description
      routeDescription
      extraDescriptionFile {
        fileKey
      }
      gallery {
        fileKey
      }
      tel
      email
      place
      street
      number
      website
      postalCode
      createdAt
      updatedAt
      deletedAt
      locationDefaultTeacherId
      locationTeacherMetaId
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      id
      title
      description
      routeDescription
      extraDescriptionFile {
        fileKey
      }
      gallery {
        fileKey
      }
      tel
      email
      place
      street
      number
      website
      postalCode
      createdAt
      updatedAt
      deletedAt
      locationDefaultTeacherId
      locationTeacherMetaId
    }
  }
`;
export const createStudentClass = /* GraphQL */ `
  mutation CreateStudentClass(
    $input: CreateStudentClassInput!
    $condition: ModelStudentClassConditionInput
  ) {
    createStudentClass(input: $input, condition: $condition) {
      id
      title
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const updateStudentClass = /* GraphQL */ `
  mutation UpdateStudentClass(
    $input: UpdateStudentClassInput!
    $condition: ModelStudentClassConditionInput
  ) {
    updateStudentClass(input: $input, condition: $condition) {
      id
      title
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const deleteStudentClass = /* GraphQL */ `
  mutation DeleteStudentClass(
    $input: DeleteStudentClassInput!
    $condition: ModelStudentClassConditionInput
  ) {
    deleteStudentClass(input: $input, condition: $condition) {
      id
      title
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const createLesson = /* GraphQL */ `
  mutation CreateLesson(
    $input: CreateLessonInput!
    $condition: ModelLessonConditionInput
  ) {
    createLesson(input: $input, condition: $condition) {
      id
      title
      lessonLessonGroupId
      weekNr
      description
      createdAt
      updatedAt
      deletedAt
      registrationLimit
      duration
      deadlineAt
      openAt
      priorityAt
      studentClassMayRegister
      gallery {
        fileKey
      }
    }
  }
`;
export const updateLesson = /* GraphQL */ `
  mutation UpdateLesson(
    $input: UpdateLessonInput!
    $condition: ModelLessonConditionInput
  ) {
    updateLesson(input: $input, condition: $condition) {
      id
      title
      lessonLessonGroupId
      weekNr
      description
      createdAt
      updatedAt
      deletedAt
      registrationLimit
      duration
      deadlineAt
      openAt
      priorityAt
      studentClassMayRegister
      gallery {
        fileKey
      }
    }
  }
`;
export const deleteLesson = /* GraphQL */ `
  mutation DeleteLesson(
    $input: DeleteLessonInput!
    $condition: ModelLessonConditionInput
  ) {
    deleteLesson(input: $input, condition: $condition) {
      id
      title
      lessonLessonGroupId
      weekNr
      description
      createdAt
      updatedAt
      deletedAt
      registrationLimit
      duration
      deadlineAt
      openAt
      priorityAt
      studentClassMayRegister
      gallery {
        fileKey
      }
    }
  }
`;
export const createLessonGroupStudentClass = /* GraphQL */ `
  mutation CreateLessonGroupStudentClass(
    $input: CreateLessonGroupStudentClassInput!
    $condition: ModelLessonGroupStudentClassConditionInput
  ) {
    createLessonGroupStudentClass(input: $input, condition: $condition) {
      id
      lessonGroupStudentClassStudentClassId
      lessonGroupStudentClassLessonGroupId
      createdAt
      updatedAt
    }
  }
`;
export const updateLessonGroupStudentClass = /* GraphQL */ `
  mutation UpdateLessonGroupStudentClass(
    $input: UpdateLessonGroupStudentClassInput!
    $condition: ModelLessonGroupStudentClassConditionInput
  ) {
    updateLessonGroupStudentClass(input: $input, condition: $condition) {
      id
      lessonGroupStudentClassStudentClassId
      lessonGroupStudentClassLessonGroupId
      createdAt
      updatedAt
    }
  }
`;
export const deleteLessonGroupStudentClass = /* GraphQL */ `
  mutation DeleteLessonGroupStudentClass(
    $input: DeleteLessonGroupStudentClassInput!
    $condition: ModelLessonGroupStudentClassConditionInput
  ) {
    deleteLessonGroupStudentClass(input: $input, condition: $condition) {
      id
      lessonGroupStudentClassStudentClassId
      lessonGroupStudentClassLessonGroupId
      createdAt
      updatedAt
    }
  }
`;
export const createLessonGroup = /* GraphQL */ `
  mutation CreateLessonGroup(
    $input: CreateLessonGroupInput!
    $condition: ModelLessonGroupConditionInput
  ) {
    createLessonGroup(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      openAt
      deadlineAt
      priorityAt
      title
      description
      deletedAt
      price
      products {
        title
        description
        tax
        priceEx
      }
    }
  }
`;
export const updateLessonGroup = /* GraphQL */ `
  mutation UpdateLessonGroup(
    $input: UpdateLessonGroupInput!
    $condition: ModelLessonGroupConditionInput
  ) {
    updateLessonGroup(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      openAt
      deadlineAt
      priorityAt
      title
      description
      deletedAt
      price
      products {
        title
        description
        tax
        priceEx
      }
    }
  }
`;
export const deleteLessonGroup = /* GraphQL */ `
  mutation DeleteLessonGroup(
    $input: DeleteLessonGroupInput!
    $condition: ModelLessonGroupConditionInput
  ) {
    deleteLessonGroup(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      openAt
      deadlineAt
      priorityAt
      title
      description
      deletedAt
      price
      products {
        title
        description
        tax
        priceEx
      }
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      monthYear
      title
      registrationLimit
      beginsAt
      endsAt
      createdAt
      updatedAt
      deletedAt
      eventLessonId
      eventLocationId
      eventTeacherMetaId
      studentClassMayRegister
      status
      eventLessonTeacherMetaLocationId
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      monthYear
      title
      registrationLimit
      beginsAt
      endsAt
      createdAt
      updatedAt
      deletedAt
      eventLessonId
      eventLocationId
      eventTeacherMetaId
      studentClassMayRegister
      status
      eventLessonTeacherMetaLocationId
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      monthYear
      title
      registrationLimit
      beginsAt
      endsAt
      createdAt
      updatedAt
      deletedAt
      eventLessonId
      eventLocationId
      eventTeacherMetaId
      studentClassMayRegister
      status
      eventLessonTeacherMetaLocationId
    }
  }
`;
export const createEventRegistration = /* GraphQL */ `
  mutation CreateEventRegistration(
    $input: CreateEventRegistrationInput!
    $condition: ModelEventRegistrationConditionInput
  ) {
    createEventRegistration(input: $input, condition: $condition) {
      id
      cognitoUsername
      present
      comment
      skill
      eventRegistrationStudentMetaId
      eventRegistrationEventId
      createdAt
      updatedAt
      deletedAt
      paidStatus
      tikkieLink
      factuurNr
    }
  }
`;
export const updateEventRegistration = /* GraphQL */ `
  mutation UpdateEventRegistration(
    $input: UpdateEventRegistrationInput!
    $condition: ModelEventRegistrationConditionInput
  ) {
    updateEventRegistration(input: $input, condition: $condition) {
      id
      cognitoUsername
      present
      comment
      skill
      eventRegistrationStudentMetaId
      eventRegistrationEventId
      createdAt
      updatedAt
      deletedAt
      paidStatus
      tikkieLink
      factuurNr
    }
  }
`;
export const deleteEventRegistration = /* GraphQL */ `
  mutation DeleteEventRegistration(
    $input: DeleteEventRegistrationInput!
    $condition: ModelEventRegistrationConditionInput
  ) {
    deleteEventRegistration(input: $input, condition: $condition) {
      id
      cognitoUsername
      present
      comment
      skill
      eventRegistrationStudentMetaId
      eventRegistrationEventId
      createdAt
      updatedAt
      deletedAt
      paidStatus
      tikkieLink
      factuurNr
    }
  }
`;
export const createLessonTeacherMetaLocation = /* GraphQL */ `
  mutation CreateLessonTeacherMetaLocation(
    $input: CreateLessonTeacherMetaLocationInput!
    $condition: ModelLessonTeacherMetaLocationConditionInput
  ) {
    createLessonTeacherMetaLocation(input: $input, condition: $condition) {
      id
      lessonTeacherMetaLocationLessonId
      lessonTeacherMetaLocationTeacherMetaId
      lessonTeacherMetaLocationLocationId
      schedulePermission {
        startsAt
        endsAt
        eventLimit
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateLessonTeacherMetaLocation = /* GraphQL */ `
  mutation UpdateLessonTeacherMetaLocation(
    $input: UpdateLessonTeacherMetaLocationInput!
    $condition: ModelLessonTeacherMetaLocationConditionInput
  ) {
    updateLessonTeacherMetaLocation(input: $input, condition: $condition) {
      id
      lessonTeacherMetaLocationLessonId
      lessonTeacherMetaLocationTeacherMetaId
      lessonTeacherMetaLocationLocationId
      schedulePermission {
        startsAt
        endsAt
        eventLimit
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteLessonTeacherMetaLocation = /* GraphQL */ `
  mutation DeleteLessonTeacherMetaLocation(
    $input: DeleteLessonTeacherMetaLocationInput!
    $condition: ModelLessonTeacherMetaLocationConditionInput
  ) {
    deleteLessonTeacherMetaLocation(input: $input, condition: $condition) {
      id
      lessonTeacherMetaLocationLessonId
      lessonTeacherMetaLocationTeacherMetaId
      lessonTeacherMetaLocationLocationId
      schedulePermission {
        startsAt
        endsAt
        eventLimit
      }
      createdAt
      updatedAt
    }
  }
`;
