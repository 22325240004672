import { Cache } from "aws-amplify";
import { stringify } from 'flatted';
import moment from "moment";
import { firstCharToLowerCase, getCacheName, addOrUpdateItemToArray } from "../utils/utils";
import updateItems from "./updateItems";
import _get from "lodash/get";
import _set from "lodash/set";

export const updateCacheAndState = async ({ item, mutationType, set, get, typeName }) => {
    const model = get().models[typeName];
    //update items and cache of own model
    await updateItems({ item, typeName, mutationType, set, get });
    // //update items and cache in other models
    if (model.connections) {
        for (const connectionKey of Object.keys(model.connections)) {
            const connection = model.connections[connectionKey];
            const relation = connection.relation;

            switch (relation) {
                case "belongsToMany":
                    //eventRegistrationEventId
                    const connectionKey = `${firstCharToLowerCase(model.typeName)}${connection.typeName}Id`;

                    //first we check if the child item is belonging to a parent item
                    if (item[firstCharToLowerCase(connection.typeName)]) {
                        //eventRegistrationByEventId_d0e59127-2e88-4451-88da-afa16ab2abcf:eventRegistrationEventId
                        //eventRegistrationByEventId_d0e59127-2e88-4451-88da-afa16ab2abcf:eventRegistrationEventId
                        let parentItem = item[firstCharToLowerCase(connection.typeName)];

                        //eventRegistrationByEventId_535f5286-bbdf-4e07-b661-15b8d4f943b2:eventRegistrationEventId 
                        const operationName = `${firstCharToLowerCase(model.typeName)}By${connection.typeName}Id`;

                        const newVariables = {};
                        newVariables[connectionKey] = item[connectionKey];
                        let cacheName = getCacheName(model.typeName, operationName, newVariables)

                        let connectionItems = _get(parentItem, `${firstCharToLowerCase(model.typeName)}s.items`);

                        //const cacheResult = await Cache.getItem(cacheName);
                        // const cache = cacheResult && parse(cacheResult);
                        const newItem = { ...item }

                        //prevent circular json
                        Reflect.deleteProperty(newItem, "defaultValues");
                        Reflect.deleteProperty(newItem, `${firstCharToLowerCase(connection.typeName)}`);

                        connectionItems = connectionItems ? addOrUpdateItemToArray(connectionItems, newItem, mutationType) : [];
                        _set(parentItem, `updatedAt`, moment().toString());
                        _set(parentItem, `${firstCharToLowerCase(model.typeName)}s.items`, connectionItems);
                        try {
                            await Cache.removeItem(cacheName);
                            await Cache.setItem(cacheName, stringify({
                                items: connectionItems
                            }));

                            await updateItems({ item: parentItem, typeName: connection.typeName, mutationType: "update", set, get });


                        } catch (err) {
                        }



                    } else {
                    }
                    //find the models
                    break;

                default:
                    break;
            }
        }
    }

}

export default updateCacheAndState;