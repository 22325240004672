import query from "./query";

export const fetchItemsNextToken = async ({ variables = {}, allItems = [], fetchConnections = [], forceRefetch, customOperationName, typeName, get }) => {

    const connections = get().models[typeName].connections;
    const cached = false;
    try {
        const res = await query("list", variables, fetchConnections, forceRefetch, customOperationName, connections, typeName, get, cached);

        // let key = targetKey ? targetKey : Object.keys(data).find(k => k.includes('list'));

        // const res = data[key]; // res = { items: [], nextToken: '' }

        allItems.push(...res.items)

        variables['nextToken'] = res.nextToken;

        if (!res.nextToken) {
            return { items: allItems };
        }



        // // eslint-disable-next-line no-param-reassign
        // variables.nextToken = res.nextToken;
        return fetchItemsNextToken({ variables, allItems, fetchConnections, forceRefetch, customOperationName, connections, typeName, get });
    }
    catch (err) {
        console.error(err)
    }

}

export default fetchItemsNextToken;