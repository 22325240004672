import moment from "moment";
import updateCacheAndState from "../model/updateCacheAndState";
import q_get from "../query/q_get";
import mutation from "./mutation";
import _ from "lodash";

export const m_softDelete= async ({ item, fetchConnections, set, get, typeName }) => {
    const newItem = _.cloneDeep(item)
    newItem.deletedAt = moment();

    const result = await mutation(
        {
            mutationType: "softDelete",
            item: newItem,
            set, get, typeName,
        }
    );

    if (result.statusCode === 200) {
        const res = await q_get({ id: result.item.id, fetchConnections, forceRefetch: true, connections: get().models[typeName].connections, typeName, get });
        updateCacheAndState({ item: { ...newItem, ...res }, mutationType: "update", set, get, typeName });
        return {
            ...result,
            item: {
                ...newItem,
                ...res,
            }
        }

    }

    return result
}

export default m_softDelete