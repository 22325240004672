import { Cache } from 'aws-amplify';
import { stringify } from 'flatted';
import { getCacheName } from "../utils/utils";
import produce from 'immer'
import {getQueryName} from "../utils/utils";
import fetchItemsNextToken from "./fetchItemsNextToken";

export const queryBy = async (operationName, variables, fetchConnections, forceRefetch, typeName, set, get) => {
    set(produce((state => {
        state.models[typeName].loading = true
    })));



    const result = await fetchItemsNextToken({
        variables: variables, allItems: [], fetchConnections: fetchConnections, forceRefetch: forceRefetch, customOperationName: operationName, typeName, get
    });


    const newVariables = { ...variables };


    Reflect.deleteProperty(newVariables, "nextToken")

    let cacheName = getCacheName(typeName, operationName, newVariables);

    await Cache.removeItem(cacheName);
    await Cache.setItem(cacheName, stringify({
        items: result.items
    }));

    const newQueries = { ...get().models[typeName].queries };
    const queryName = getQueryName(operationName, newVariables);

    newQueries[queryName] = {
        typeName: typeName,
        operationName: operationName,
        variables: newVariables,
    }


    set(produce((state => {
        state.models[typeName].loading = false
        state.models[typeName].queries = newQueries
        state.models[typeName].items[queryName] = result.items
    })));




    return result.items;
}

export default queryBy;