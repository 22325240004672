import { Cache } from "aws-amplify";
import { stringify } from 'flatted';
import produce from "immer";
import _ from "lodash";
import { addOrUpdateItemToArray, getCacheName } from "../utils/utils";




export const updateItems = async ({ item, typeName, mutationType, set, get }) => {
    const model = get().models[typeName];
    for (const key of Object.keys(model.queries)) {
        const variables = model.queries[key].variables;
        const operationName = model.queries[key].operationName

        Reflect.deleteProperty(item, "defaultValues");
        


        const newItems = addOrUpdateItemToArray(model.items[key], _.cloneDeep(item), mutationType);

        let cacheName = getCacheName(model.typeName, operationName, variables);

        try {
            await Cache.removeItem(cacheName);
        }
        catch (e) {
            console.error(e)
            alert("ERROR")
        }
        try {
            await Cache.setItem(cacheName,
                stringify({
                    items: newItems
                })

            )
        }
        catch (e) {
            alert("ERROR")
        }

        try{
            set(produce((state => {
                state.models[model.typeName].items[key] = newItems
            })));
        }
        catch (e) {
            alert("COULD NOT SET ITEMS RELOADING PAGE TO FIX EVERYTHING"+  key)
            window.location.reload();
        }
       
    }
}

export default updateItems