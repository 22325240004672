import { Cache } from "aws-amplify";
import { stringify } from 'flatted';
import produce from "immer";
import _ from "lodash";
import isEqual from "lodash/isEqual";
import { decycle, firstCharToLowerCase, getCacheName, getQueryName } from "../utils/utils";
import mutation from "./mutation";
export const syncConnections = async ({ item, mutationType, typeName, set, get }) => {
    const model = get().models[typeName];
    //get all connections
    for (const connectionKey of Object.keys(model.connections)) {
        const connection = model.connections[connectionKey];
        const connectionModel = get().models[connectionKey];

        let connectionName = firstCharToLowerCase(connection.typeName);
        connectionName += "s";

        let newItems = item[connectionName] && item[connectionName].items ? [...item[connectionName].items] : [];
        let oldItems = item[connectionName] && item[connectionName].oldItems ? [...item[connectionName].oldItems] : [];


        //check if the item that needs to be created has a belongsTo connection
        if (connectionModel && connectionModel.connections) {
            Object.keys(connectionModel.connections).forEach((subConnectionKey) => {
                const subConnection = connectionModel.connections[subConnectionKey];

                if (subConnection.relation === "belongsToMany" || subConnection.relation === "belongsTo" || subConnection.relation === "hasOne") {
                    //inject recently create or updated item so it can be connected
                    newItems = newItems && _.cloneDeep(newItems).map((newItem) => {
                        newItem[firstCharToLowerCase(model.typeName)] = item;
                        newItem[firstCharToLowerCase(model.typeName) + "Id"] = item.id;
                        return newItem;
                    });

                    oldItems = oldItems && oldItems.map((oldItem) => {
                        oldItem[firstCharToLowerCase(model.typeName)] = item;
                        oldItem[firstCharToLowerCase(model.typeName) + "Id"] = item.id;
                        return oldItem;
                    })
                }
            })
        }

        if (connection.relation === 'hasMany' || connection.relation === 'hasManyThrough') {
            //hasmany check if the
            let createItems = [];
            //newItems.filter((newItem) => !oldItems.includes(newItem) && !newItem.id)
            //does not include oldItems and
            let updateItems = [];
            //newItems.filter((newItem) => !oldItems.includes(newItem) && newItem.id)
            let deleteItems = [];



            let checkItems = [...newItems];


            //if the parent was deleted delete all items
            if (mutationType === "delete") {
                deleteItems = newItems;
            } else {
                //check if there is a new item created
                //if there is no id it means it's new
                checkItems = checkItems.filter((newItem, index) => {
                    const findIndex = oldItems.findIndex((oldItem) => oldItem.id === newItem.id);
                
                    if (findIndex === -1 && !newItem.id) {
                        createItems.push(newItem)
                        return false
                    } else {
                        return true;
                    }
                });
                //check if values are not the same
                //if it has a id it needs to be updated

                checkItems = checkItems.filter((newItem, index) => {
                    let shouldUpdate = false;
                    const oldItem = oldItems.find((oldItem) => oldItem.id === newItem.id);

                    oldItem && Object.keys(oldItem).forEach((key) => {
                        //dont compare commfieldArray ids will always be different
                        if (!isEqual(oldItem[key], newItem[key]) && key !== "fieldId") {
                            let keyIsConnection = false;
                            Object.keys(connectionModel.connections).forEach((connectionKey) => {

                                let connectionName = firstCharToLowerCase(connectionModel.connections[connectionKey].typeName);

                                if (connection.relation === 'hasManyThrough' || connectionModel.connections[connectionKey].relation === "hasMany") {
                                    connectionName += "s";
                                }
                                // //if an item is passed it should be mutated
                                // //create some sort of check if for HasOne / BelongsTo
                                if (key === connectionName) {
                                    //connections dont count as field change as they are not saved in the model itself
                                    keyIsConnection = true;
                                }
                            })



                            if (!keyIsConnection) {

                                shouldUpdate = true;
                            }
                        }
                    })

                    if (shouldUpdate) {
                        updateItems.push(newItem)
                        return false
                    } else {
                        return true;
                    }


                });
                //if id does not exist in newItems array it needs to be deleted
                deleteItems = oldItems.filter((oldItem, index) => newItems.findIndex((newItem) => newItem.id === oldItem.id) === -1)
            }

            const createItemsResult = [];
            const updateItemsResult = [];

            for (const createItem of createItems) {
                const result = await mutation(
                    {
                        mutationType: "create",
                        item: createItem,
                        get,
                        set,
                        typeName: connection.typeName
                    }
                );
                createItemsResult.push(result.item);
            }

            for (const updateItem of updateItems) {
                const result = await mutation(
                    {
                        mutationType: "update",
                        item: updateItem,
                        get,
                        set,
                        typeName: connection.typeName
                    }
                );

                updateItemsResult.push(result.item);
            }


            for (const deleteItem of deleteItems) {

                if (deleteItem && deleteItem.id) {
                    await mutation(
                        {
                            mutationType: "delete",
                            item: deleteItem,
                            get,
                            set,
                            typeName: connection.typeName
                        }
                    );
                }

            }


            const uneditedConnectionItems = checkItems.map((checkItem) => {
                Reflect.deleteProperty(checkItem, firstCharToLowerCase(connection.typeName))
                return decycle(checkItem)
            })
            const connectionItems = [...createItemsResult, ...updateItemsResult, ...uneditedConnectionItems];

            if (connection.relation === "hasManyThrough") {
                for (const connectionItem of connectionItems) {

                    //updateLesson
                    for (const subConnectionKey of Object.keys(connectionModel.connections)) {
                        const subConnection = connectionModel.connections[subConnectionKey];

                        if (subConnection.relation === "belongsTo") {
                            const connectionKey = `${firstCharToLowerCase(connection.typeName)}${subConnection.typeName}Id`;
                            if (connectionItem[connectionKey]) {
                                const operationName = `${firstCharToLowerCase(connection.typeName)}By${subConnection.typeName}Id`;

                                const newVariables = {};
                                newVariables[connectionKey] = connectionItem[connectionKey];
                                let cacheName = getCacheName(connection.typeName, operationName, newVariables);

                                const queryName = getQueryName(operationName, newVariables);
                                set(produce((state => {
                                    state.models[connection.typeName].queries[queryName] = {
                                        typeName: connection.typeName,
                                        operationName: operationName,
                                        variables: newVariables,
                                    }
                                })));


                                try {
                                    await Cache.removeItem(cacheName);
                                }

                                catch (e) {
                                    alert("ERROR")
                                }

                                try {
                                    await Cache.setItem(cacheName,

                                        stringify({
                                            items: connectionItems
                                        })

                                    )
                                }
                                catch (e) {
                                    alert("ERROR")
                                }

                            }

                        }
                    }
                }

            }
            if (connection.relation === "hasMany") {
                const connectionKey = `${firstCharToLowerCase(connection.typeName)}${model.typeName}Id`;
                const operationName = `${firstCharToLowerCase(connection.typeName)}By${model.typeName}Id`;

                const newVariables = {};
                newVariables[connectionKey] = item.id;

                let cacheName = getCacheName(model.typeName, operationName, newVariables);


                const queryName = getQueryName(operationName, newVariables);

                set(produce((state => {
                    state.models[typeName].queries[queryName] = {
                        typeName: typeName,
                        operationName: operationName,
                        variables: newVariables,
                    }
                })));

                try {
                    await Cache.removeItem(cacheName);
                }
                catch (e) {
                    alert("ERROR")
                }
                try {
                    await Cache.setItem(cacheName,
                        stringify({
                            items: connectionItems
                        })

                    )
                }
                catch (e) {
                    alert("ERROR")
                }
            }

            for (const checkItem of checkItems) {
                await syncConnections
                    ({
                        item: checkItem,
                        set, get,
                        mutationType,
                        typeName,
                    })
            }
        }



    }
}

export default syncConnections;