import { Auth } from "aws-amplify";
import moment from "moment";
import invokeLambda from "./lambda/lambda";
// import EventModel from "./event";
// import LessonGroupModel from "./lessonGroup";
// import LocationModel from "./location";
// import EventRegistrationModel from "./eventRegistration";
// import StudentClassModel from "./studentClass";
// import TeacherMetaModel from "./teacherMeta";
// import StudentMetaModel from "./studentMeta";
// import LessonModel from "./lesson";
import { formatConnections } from "./utils/utils";

export const EventModel = {
    typeName: "Event",
    fillables: [
        "title",
        "beginsAt",
        "endsAt",
        "status",
        "registrationLimit",
        "studentClassMayRegister",
        "eventLessonId",
        "eventLocationId",
        "eventTeacherMetaId",
        "eventLessonTeacherMetaLocationId",
        "monthYear",
    ],
    formatItem: (item, fillables, connections, typeName) => {
        let newItem = {};


        item['monthYear'] = moment(item.beginsAt).format("MM/YYYY")


        fillables.forEach((key) => {
            newItem[key] = item[key];
        });

        formatConnections(connections, item, newItem, "Event")
        return newItem;
    },
    mutationDepth: 1,
    connections: {
        "Lesson": {
            typeName: "Lesson",
            relation: "belongsTo",
        },
        "Location": {
            typeName: "Location",
            relation: "belongsTo",
        },
        "TeacherMeta": {
            typeName: "TeacherMeta",
            relation: "belongsTo",
        },
        "EventRegistration": {
            typeName: "EventRegistration",
            relation: "hasMany",
        },
        "LessonTeacherMetaLocation": {
            typeName: "LessonTeacherMetaLocation",
            relation: "belongsTo",
        }
    },
    isOpen: ({ event }) => {
        if (event.eventRegistrations.items.length >= event.registrationLimit) {
            return {
                open: false,
                message: "les is vol",
                status: "LessonIsFull"
            }
        }
        if (!moment(event.beginsAt).isAfter(moment())) {
            return {
                open: false,
                message: "les is al geweest",
                status: "LessonIsPast"
            }
        }
        return {
            open: true
        }

    }

}

export const EventRegistrationModel = {
    typeName: "EventRegistration",
    fillables: [
        "cognitoUsername",
        "present",
        "comment",
        "skill",
        "paidStatus",
        "tikkieLink",
        "factuurNr",
    ],
    formatItem: (item, fillables, connections, typeName) => {
        let newItem = {};
        newItem = formatConnections(connections, item, newItem, "EventRegistration")



        fillables.forEach((key) => {
            newItem[key] = item[key];
        });

        return newItem;
    },
    mutationDepth: 1,
    connections: {
        "StudentMeta": {
            typeName: "StudentMeta",
            relation: "belongsTo",
        },
        "Event": {
            typeName: "Event",
            relation: "belongsToMany",
        },

    },
    getPaidStatus: async ({ lessonGroup, studentMeta, eventRegistrations }, set, get) => {
        //reasons: AlreadyRegistered, isRegular, isBasic.

        let paidStatus = {
            status: "NotFound",
            reason: "Unkown",

        };

        if (!lessonGroup.products || lessonGroup.products.length === 0) {
            paidStatus.status = "SkipPayment";
            paidStatus.reason = "NoProducts";
            paidStatus.dynamoDB = "COMPENSATED";
            return paidStatus;
        }




        const LessonGroupModel = get().models["LessonGroup"];

        const isRegistered = await LessonGroupModel.isRegistered({ lessonGroup, eventRegistrations }, set, get);
        if (isRegistered) {
            paidStatus.status = "MustPay";
            paidStatus.reason = "AlreadyRegistered"
            paidStatus.dynamoDB = "FACTUURSTUREN"
            return paidStatus;
        }

        if (studentMeta.studentClass && studentMeta.studentClassType === "basic") {
            paidStatus.status = "MustPay";
            paidStatus.reason = "studentClassTypeMustPay";
            paidStatus.dynamoDB = "FACTUURSTUREN";
            return paidStatus;

        }


        if (
            studentMeta.studentClassType === "regular" ||
            studentMeta.studentClassType === "elite" ||
            studentMeta.studentClassType === "classic"

        ) {
            paidStatus.status = "Compensated";
            paidStatus.reason = "studentClassTypeCompensated";
            paidStatus.dynamoDB = "COMPENSATED";
            return paidStatus;

        }







    }
}

export const LessonModel = {
    typeName: "Lesson",
    fillables: [
        "title",
        "weekNr",
        "description",
        "registrationLimit",
        "duration",
        "deadlineAt",
        "openAt",
        "priorityAt",
        // "studentClassMayRegister",
        "gallery",
    ],
    formatItem: (item, fillables, connections, typeName) => {
        let newItem = {};
        newItem = formatConnections(connections, item, newItem, typeName)



        fillables.forEach((key) => {
            newItem[key] = item[key];
        });


        return newItem;
    },
    mutationDepth: 4,
    connections: {
        "LessonGroup": {
            typeName: "LessonGroup",
            relation: "belongsTo",
        },
        "LessonTeacherMetaLocation": {
            typeName: "LessonTeacherMetaLocation",
            relation: "hasManyThrough",
        }
    }
}

export const LessonGroupModel = {
    typeName: "LessonGroup",
    fillables: [
        "title",
        "description",
        "openAt",
        "priorityAt",
        "deadlineAt",
        "price",
        "products",
    ],
    formatItem: (item, fillables, connections, typeName) => {
        let newItem = {};
        newItem = formatConnections(connections, item, newItem, typeName)



        fillables.forEach((key) => {
            newItem[key] = item[key];
        });

        return newItem;
    },
    mutationDepth: 3,
    connections: {
        "Lesson": {
            typeName: "Lesson",
            relation: "hasMany",
        },
        "LessonGroupStudentClass": {
            typeName: "LessonGroupStudentClass",
            relation: "hasManyThrough",
        }
    },

    //extend
    isOpen: async ({ lessonGroup, priority, user }, set, get) => {
        const openAtDifferenceFromToday = Math.round(moment(lessonGroup.openAt).diff(moment(), "d", true));
        const isOpen = moment().isBetween(priority ? lessonGroup.priorityAt : lessonGroup.openAt, lessonGroup.deadlineAt, undefined, '[]');


        const eventRegistrations = await get().models["EventRegistration"].queryBy("eventRegistrationByStudentMetaId", {
            eventRegistrationStudentMetaId: user['customMetaInfo'].id
        }, []);

        // const eventRegistrations = []

        lessonGroup.isRegisteredStatus = await get().models["LessonGroup"].isRegistered({ lessonGroup, eventRegistrations }, set, get)
        if (lessonGroup.isRegisteredStatus) {
            return "closed"
        }

        if (isOpen) {

            return "open";
        } else if (openAtDifferenceFromToday >= 0) {

            return "upcoming";
        } else {

            return "closed"
        }
    },
    isRegistered: async ({ lessonGroup, eventRegistrations }, set, get) => {
        let isRegistered = false;

        const promises = [];
        if (eventRegistrations) {
            for (const eventRegistration of eventRegistrations) {
                promises.push(
                    await get().models["Event"].get(eventRegistration.eventRegistrationEventId, [{
                        typeName: "Lesson",
                    }], false)
                )

            }
        }


        await Promise.all(promises).then((events) => {
            events.forEach((event) => {
                if (event && event.lesson && event.lesson.lessonLessonGroupId === lessonGroup.id) {

                    isRegistered = true
                }
            })
        })
        return isRegistered;
    },


}

export const LocationModel = {
    typeName: "Location",
    fillables: [
        "tel",
        "street",
        "number",
        "postalCode",
        "place",
        "description",
        "routeDescription",
        "email",
        "title",
        "gallery",
        "extraDescriptionFile",
        "website",
    ],
    formatItem: (item, fillables, connections, typeName) => {
        let newItem = {};
        newItem = formatConnections(connections, item, newItem, typeName)



        fillables.forEach((key) => {
            newItem[key] = item[key];
        });


        return newItem;
    },
    mutationDepth: 2,
    connections: {
        'TeacherMeta': {
            relation: "belongsTo",
            typeName: "TeacherMeta",
        }
    }
}

export const StudentClassModel = {
    typeName: "StudentClass",
    fillables: [
        "title",
        "type",
    ],
    formatItem: (item, fillables, connections, typeName) => {
        let newItem = {};
        newItem = formatConnections(connections, item, newItem, typeName)



        fillables.forEach((key) => {
            newItem[key] = item[key];
        });

        return newItem;
    },
    mutationDepth: 1
}

export const StudentMetaModel = {
    typeName: "StudentMeta",
    fillables: [
        "name",
        "tel",
        "email",
        "cognitoUsername",
        "disabled",
        "studentClassType",
        "priority",
        "clientNr"
    ],
    formatItem: (item, fillables, connections, typeName) => {
        let newItem = {};
        newItem = formatConnections(connections, item, newItem, typeName)



        fillables.forEach((key) => {
            newItem[key] = item[key];
        });

        return newItem;
    },
    mutationDepth: 1,
    connections: {
        "StudentClass": {
            relation: "belongsTo",
            typeName: "StudentClass",
        },
        "EventRegistration": {
            relation: "hasMany",
            typeName: "EventRegistration",
        }
    },
    disableUser: async ({ item }, set, get) => {
        item.disabled = true;

        await get().mutation({
            typeName: "StudentMeta",
            mutationType: "update",
            item,
            get, set
        });

        let result = await invokeLambda("disableUser", {
            "username": item.cognitoUsername
        });


        return result

    },
    enableUser: async ({ item }, set, get) => {
        item.disabled = false;

        await get().mutation({
            typeName: "StudentMeta",
            mutationType: "update",
            item,
            get, set
        });

        let result = await invokeLambda("enableUser", {
            "username": item.cognitoUsername
        });

        return result;
    },
    resetTemporaryPassword: async ({ student }, set, get) => {
        const newStudent = {
            username: student.email,
            email: student.email.toLowerCase(),
            groupName: "students",
        };
        try {
            const result = await invokeLambda("resendTemporaryPassword", newStudent);

            if (result["FunctionError"]) {
                const payload = JSON.parse(result["Payload"]);

                throw (payload)
            } else {
                const payload = JSON.parse(result["Payload"]);
            }


        }
        catch (e) {
            alert("Resetten niet mogelijk, student moet wachtwoord aanvragen via 'wachtwoord vergeten pagina'")
        }

    },
    afterMutation: async ({ item, mutationType, set, get, typeName }) => {
        let result = "";
        switch (mutationType) {
            case "create":
                const student = {
                    username: item.email,
                    email: item.email.toLowerCase(),
                    groupName: "students",
                };

                result = await invokeLambda("createUserAndAddToGroup", student);

                if (result["FunctionError"]) {
                    const payload = JSON.parse(result["Payload"]);
                    // this.enqueueSnackbar(`Account niet kunnen aanmaken in cognito, neem contact op met admin)`,
                    //     {
                    //         variant: 'error',
                    //     }
                    // );
                    throw (payload)
                } else {
                    const payload = JSON.parse(result["Payload"]);

                    item['cognitoUsername'] = payload.User.Username;


                    await get().mutation({
                        typeName,
                        mutationType: "update",
                        item,
                        get, set
                    });

                    // this.enqueueSnackbar(`Account gesynchroniseerd met cognito`,
                    //     {
                    //         variant: 'success',
                    //     }
                    // );
                }
                break;
            case "update":

                const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
                if (currentAuthenticatedUser.signInUserSession.accessToken.payload["cognito:groups"].includes("admins")) {
                    const student = {
                        username: item.cognitoUsername,
                        userAttributes: [
                            {
                                Name: "email",
                                Value: item.email.toLowerCase(),

                            },
                        ],
                    };

                    result = await invokeLambda("updateUserAttributes", student);
                    if (result["FunctionError"]) {
                        const payload = JSON.parse(result["Payload"]);
                        // this.enqueueSnackbar(`Account niet kunnen synchroniseren cognito, neem contact op met admin`,
                        //     {
                        //         variant: 'error',
                        //     }
                        // );
                        throw (payload)
                    } else {
                        // this.enqueueSnackbar(`Account gesynchroniseerd met cognito`,
                        //     {
                        //         variant: 'success',
                        //     }
                        // );

                    }

                } else {
                    await Auth.updateUserAttributes(currentAuthenticatedUser, {
                        email: item.email
                    });

                };

                //check if admin
                break
            default:
        }
    },
}

export const TeacherMetaModel = {
    typeName: "TeacherMeta",
    fillables:
        [
            "email",
            "cognitoUsername",
            "name",
            "tel",
            "color",
            "disabled",
        ],
    formatItem: (item, fillables, connections, typeName) => {
        let newItem = {};
        newItem = formatConnections(connections, item, newItem, typeName)


        fillables.forEach((key) => {
            newItem[key] = item[key];
        });



        return newItem;
    },
    mutationDepth: 2,
    connections: {
        "LessonTeacherMetaLocation": {
            relation: "hasManyThrough",
            typeName: "LessonTeacherMetaLocation",
        },

    },
    afterMutation: async ({ item, mutationType, set, get, typeName }) => {
        let result = "";
        switch (mutationType) {
            case "create":
                const teacher = {
                    username: item.email,
                    email: item.email.toLowerCase(),
                    groupName: "teachers",
                };

                result = await invokeLambda("createUserAndAddToGroup", teacher);

                if (result["FunctionError"]) {
                    const payload = JSON.parse(result["Payload"]);
                    // this.enqueueSnackbar(`Account niet kunnen aanmaken in cognito, neem contact op met admin)`,
                    //     {
                    //         variant: 'error',
                    //     }
                    // );
                    throw (payload)
                } else {
                    const payload = JSON.parse(result["Payload"]);

                    item['cognitoUsername'] = payload.User.Username;


                    await get().mutation({
                        typeName,
                        mutationType: "update",
                        item,
                        get, set
                    });

                    // this.enqueueSnackbar(`Account gesynchroniseerd met cognito`,
                    //     {
                    //         variant: 'success',
                    //     }
                    // );
                }
                break;
            case "update":

                const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
                if (currentAuthenticatedUser.signInUserSession.accessToken.payload["cognito:groups"].includes("admins")) {
                    const teacher = {
                        username: item.cognitoUsername,
                        userAttributes: [
                            {
                                Name: "email",
                                Value: item.email.toLowerCase(),

                            },
                        ],
                    };

                    result = await invokeLambda("updateUserAttributes", teacher);
                    if (result["FunctionError"]) {
                        const payload = JSON.parse(result["Payload"]);
                        // this.enqueueSnackbar(`Account niet kunnen synchroniseren cognito, neem contact op met admin`,
                        //     {
                        //         variant: 'error',
                        //     }
                        // );
                        throw (payload)
                    } else {
                        // this.enqueueSnackbar(`Account gesynchroniseerd met cognito`,
                        //     {
                        //         variant: 'success',
                        //     }
                        // );

                    }

                } else {
                    await Auth.updateUserAttributes(currentAuthenticatedUser, {
                        email: item.email
                    });

                };

                //check if admin
                break
            default:
        }
    },
    disableUser: async ({ item }, set, get) => {
        item.disabled = true;

        await get().mutation({
            typeName: "TeacherMeta",
            mutationType: "update",
            item,
            get, set
        });

        let result = await invokeLambda("disableUser", {
            "username": item.cognitoUsername
        });


        return result

    },
    enableUser: async ({ item }, set, get) => {
        item.disabled = false;

        await get().mutation({
            typeName: "TeacherMeta",
            mutationType: "update",
            item,
            get, set
        });

        let result = await invokeLambda("enableUser", {
            "username": item.cognitoUsername
        });

        return result;
    }
};

export const LessonTeacherMetaLocationModel = {
    typeName: "LessonTeacherMetaLocation",
    fillables: [
        "schedulePermission",
        "locationRole",
    ],
    formatItem: (item, fillables, connections, typeName) => {
        let newItem = {};
        newItem = formatConnections(connections, item, newItem, typeName)

        fillables.forEach((key) => {
            newItem[key] = item[key];
        });

        return newItem;
    },
    mutationDepth: 2,
    connections: {
        "TeacherMeta": {
            relation: "belongsTo",
            typeName: "TeacherMeta",
        },
        "Lesson": {
            relation: "belongsTo",
            typeName: "Lesson",
        },
        "Location": {
            relation: "belongsTo",
            typeName: "Location",
        },
        "Event": {
            typeName: "Event",
            relation: "hasMany",
        }

    }
}

export const LessonGroupStudentClassModel = {
    typeName: "LessonGroupStudentClass",
    fillables: ["lessonGroupStudentClassStudentClassId", "lessonGroupStudentClassLessonGroupId"],
    formatItem: (item, fillables, connections, typeName) => {
        let newItem = {};
        newItem = formatConnections(connections, item, newItem, typeName)

        fillables.forEach((key) => {
            newItem[key] = item[key];
        });

        return newItem;
    },
    mutationDepth: 2,
    connections: {
        "LessonGroup": {
            relation: "belongsTo",
            typeName: "LessonGroup",
        },
        "StudentClass": {
            relation: "belongsTo",
            typeName: "StudentClass",
        },


    }
};