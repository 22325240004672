
import { API, Cache, graphqlOperation } from 'aws-amplify';
import { parse, stringify } from 'flatted';
import moment from 'moment';
import * as queries from "../../../../src/graphql/queries";
import { getCacheName } from "../utils/utils";
import resolveConnections from "./resolveConnections";

export const query = (mutationType, variables, fetchConnections, forceRefetch = false, customOperationName, connections, typeName, get, cached = true) => {
    return new Promise(async (resolve, reject) => {
        let fetchFromCache = false;
        let operationName = mutationType === "list" ? mutationType + typeName + "s" : mutationType + typeName;

        if (customOperationName) {
            operationName = customOperationName;
        }

        const newVariables = { ...variables };
        Reflect.deleteProperty(newVariables, "nextToken");

        let cacheName = getCacheName(typeName, operationName, variables)

        const cacheResult = await Cache.getItem(cacheName);
        const cache = cacheResult && parse(cacheResult);





        if (forceRefetch || !cache) {
            let result = undefined;
            let items = [];


            try {
                const promise = API.graphql(
                    graphqlOperation(queries[operationName],
                        { ...variables },
                    )
                )
                result = await promise;
            } catch (e) {
                console.error(e)
                console.error("QUERY HAS FAILED", operationName)
                //retry for net::ERR_HTTP2_PROTOCOL_ERROR 
                if (e.errors && e.errors[0] && e.errors[0].message && e.errors[0].message === "Network Error") {
                    try {
                        const promise = API.graphql(
                            graphqlOperation(queries[operationName],
                                { ...variables },
                            )
                        )
                        result = await promise;
                    }
                    catch {
                        console.error(e)
                        console.error("QUERY HAS FAILED", operationName)
                        return {
                            statusCode: 400,
                            errors: e.errors
                        }
                    }
                } else {
                    return {
                        statusCode: 400,
                        errors: e.errors
                    }
                }
            }





            //push items to items array to make life easier
            if (mutationType === "get") {
                items.push(result.data[operationName]);
            }


            if (mutationType === "list") {
                items = result.data[operationName].items;
            }

            let promises = resolveConnections(items, typeName, connections, fetchConnections, get);


            Promise.all(promises).then(async (connections) => {
                connections.forEach((connection) => {
                    const updateIndex = items.findIndex((item) => {
                        return item.id === connection.belongsTo.id
                    })

                    items[updateIndex][connection.key] = connection.item
                })

                if (mutationType === "get") {
                    if (cached) {
                        const newCacheValue = {
                            timeStamp: moment().toISOString(),
                            item: items[0],
                        };
                        await Cache.removeItem(cacheName);

                        await Cache.setItem(cacheName, stringify(newCacheValue),
                            {
                                expires: moment().add("15", "m").valueOf()
                            }
                        );
                    }


                    resolve(items[0])
                }

                if (mutationType === "list") {
                    if (cached) {
                        const newCacheValue = {
                            items: items,
                        };

                        try {

                            await Cache.setItem(cacheName, stringify(newCacheValue),
                                {
                                    expires: moment().add("15", "m").valueOf()
                                }
                            );
                        }
                        catch (e) {
                            alert("FAILED")
                        }
                    }



                    resolve({
                        items: items,
                        nextToken: result.data[operationName].nextToken,
                    })
                }


            })



        } else {
            if (mutationType === "get") {
                const { item } = parse(Cache.getItem(cacheName));
                let newItem = {...item};
                let promises = resolveConnections([item], typeName, connections, fetchConnections, get);
                Promise.all(promises).then((connections) => {
                    connections.forEach((connection) => {
                   
                        newItem[connection.key] = connection.item
                    })


                })

                resolve(newItem)
            }

            if (mutationType === "list") {
                const { items } = parse(Cache.getItem(cacheName));
                let promises = resolveConnections(items, typeName, connections, fetchConnections, get);


                Promise.all(promises).then((connections) => {
                    connections.forEach((connection) => {
                        const updateIndex = items.findIndex((item) => {
                            return item.id === connection.belongsTo.id
                        })

                        items[updateIndex][connection.key] = connection.item
                    })

                    resolve({ items: items, nextToken: null })

                })
            }
        }

    })
}

export default query;