import query from "./query"

const q_get = async ({ id, fetchConnections, forceRefetch, connections, typeName, get}) => {
    //mutationType, variables, fetchConnections, forceRefetch, customOperationName, connections, typeName, get
    const result = await query("get", {
        id: id,
    }, fetchConnections, forceRefetch, undefined, connections, typeName, get);



    return result;
}

export default q_get;