import produce from 'immer';
import create from 'zustand';
import mutation from "./mutation/mutation";
import m_create from "./mutation/m_create";
import m_delete from "./mutation/m_delete";
import m_restore from "./mutation/m_restore";
import m_softDelete from "./mutation/m_softDelete.js";
import m_update from "./mutation/m_update";
import listAll from "./query/listAll";
import queryBy from "./query/queryBy";
import q_get from "./query/q_get";


const useAmplifyApi = create((set, get) => ({
    messages: [],
    models: {},
    registerModels: (models) => {
        Object.keys(models).forEach((modelKey) => {
            get().addModel({ ...models[modelKey] })
        })
    },
    mutation: async ({
        mutationType,
        item,
        fetchConnections,
        typeName
    }) => {
        const res = await mutation({ mutationType, fetchConnections, item, typeName, set, get });
        return res;
    },

    addModel: ({ typeName, fillables, formatItem, mutationDepth, connections, afterMutation, ...props }) => {
        const newModels = { ...get().models };
        newModels[typeName] = {
            afterMutation: afterMutation,
            queries: {},
            typeName: typeName,
            items: {},
            formatItem,
            fillables,
            mutationDepth,
            depth: 0,
            connections: connections,
            allItems: [],
            get: async (id, fetchConnections, forceRefetch) => {
                const res = await q_get({ id, fetchConnections, forceRefetch, connections: get().models[typeName].connections, typeName, get })
                return res;
            },
            listAll: async (variables, fetchConnections, forceRefetch) => {
                const res = await listAll(variables, fetchConnections, forceRefetch, typeName, set, get);
                return res;
            },
            queryBy: async (operationName, variables, fetchConnections, forceRefetch) => {
                const res = await queryBy(operationName, variables, fetchConnections, forceRefetch, typeName, set, get);
                return res;
            },
            create: async (item, fetchConnections) => {
                set(produce((state => { state.models[typeName].loading = true })));



                const res = await m_create({ item, fetchConnections, typeName, set, get });

                get().models[typeName].afterMutation && get().models[typeName].afterMutation({ item, mutationType: "create", set, get, typeName });

                set(produce((state => { state.models[typeName].loading = false })));

                return res;
            },
            update: async (item, fetchConnections) => {
                set(produce((state => { state.models[typeName].loading = true; })))


                const res = await m_update({ item, fetchConnections, typeName, set, get });

                get().models[typeName].afterMutation && get().models[typeName].afterMutation({ item, mutationType: "update", set, get, typeName });

                set(produce((state => {
                    state.models[typeName].loading = false
                })));


                return res;
            },
            restore: async (item, fetchConnections) => {
                set(produce((state => { state.models[typeName].loading = true })));
                const res = await m_restore({ item, fetchConnections, typeName, set, get });

                get().models[typeName].afterMutation && get().models[typeName].afterMutation({ item, mutationType: "restore", set, get, typeName });

                set(produce((state => { state.models[typeName].loading = false })));

                return res;
            },
            delete: async (item) => {
                set(produce((state => { state.models[typeName].loading = true })));
                const res = await m_delete({ item, typeName, set, get });

                get().models[typeName].afterMutation && get().models[typeName].afterMutation({ item, mutationType: "delete", set, get, typeName });

                set(produce((state => { state.models[typeName].loading = false })));

                return res;
            },
            softDelete: async (item, fetchConnections) => {
                set(produce((state => { state.models[typeName].loading = true })));
                const res = await m_softDelete({ item, fetchConnections, typeName, set, get });

                get().models[typeName].afterMutation && get().models[typeName].afterMutation({ item, mutationType: "softDelete", set, get, typeName });

                set(produce((state => { state.models[typeName].loading = false })));
                return res;
            },

        }

        // newModels[typeName].isOpen = () => {
        //     return "closed"
        // }
        const extendedFunctions = {};
        if (props) {
            for (const extendedFunctionName of Object.keys(props)) {
                const callBack = (passedProps) => {
                    return props[extendedFunctionName] && props[extendedFunctionName](passedProps, set, get)
                }
                extendedFunctions[extendedFunctionName] = callBack
                // newModels[typeName][key] = () => {
                //    // return newModels[typeName][key](set, get);
                // }

            }

        }
        newModels[typeName] = { ...newModels[typeName], ...extendedFunctions }
        set({ models: newModels })
    },

}));

export default useAmplifyApi;
