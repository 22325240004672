import React, { useEffect, useState } from "react";
import useAmplifyApi from "../useAmplifyApi";
import * as models from "../_models";

export const RegisterModels = () => {
    const registerModels = useAmplifyApi((state) => state.registerModels);

    const [mounted, setMounted] = useState(false);

    useEffect(() => {

        !mounted && registerModels(models)

        setMounted(true);
    }, [mounted, registerModels])

    return <></>

}

const AmplifyApiProvider = (props) => {
    return <>
        <RegisterModels />
        {props.children}
    </>
}

export default AmplifyApiProvider