import { API, Cache, graphqlOperation } from 'aws-amplify';
import { stringify } from 'flatted';
import produce from "immer";
import moment from "moment";
import * as mutations from "../../../../src/graphql/mutations";
import syncConnections from "./syncConnections";
export const mutation = async (
    {
        mutationType,
        item,
        set,
        get,
        typeName,
    }
) => {
    let mutationItem = {};
    const model = get().models[typeName]


    if (mutationType !== "delete" && mutationType !== "softDelete" && mutationType !== "restore") {
        mutationItem = item && await model.formatItem({ ...item }, model.fillables, model.connections, typeName);
    }

    if (item.id) {
        mutationItem.id = item.id;
    }

    let _mutationType = mutationType;

    if (mutationType === "softDelete") {
        mutationItem["deletedAt"] = moment();
        _mutationType = "update";
    }

    if (mutationType === "restore") {
        _mutationType = "update";
        mutationItem["deletedAt"] = null

    }

    //before the mutation we check if the item is belongsTo something
    if (mutationType === "delete") {
        mutationItem["deletedAt"] = null
        mutationItem = {
            "id": item['id'],
        }
    }

    if (mutationType === "create") {
        Reflect.deleteProperty(mutationItem, "id")
    }

    let result

    try {
        result =
            await API.graphql(
                graphqlOperation(mutations[_mutationType + typeName], {
                    input: { ...mutationItem },
                }
                )
            );
    }
    catch (e) {
        alert("FAILED")
        return {
            statusCode: 400,
            errors: e.errors,
            e: e,
            mutationItem: mutationItem,
            item: item,
        };
    }



    //after the mutation we check if the item has childs that need to be createdAt
    //hasmany
    if (model.connections && Object.keys(model.connections).length > 0 && model.depth < model.mutationDepth) {
        // set(produce((state => {
        //     state.models[typeName].depth++
        // })));

        await syncConnections({
            item: {
                ...item,
                id: result.data[_mutationType + typeName].id,
            }, mutationType: _mutationType, typeName, set, get
        })
    }

    if (model.afterMutation) {
        try {
            await model.afterMutation({ item: result.data[_mutationType + model.typeName], mutationType, set, get, typeName });

        }

        catch (e) {
            return {
                statusCode: 400,
                e: e,
                errors: e.errorMessage,
                mutationItem: mutationItem,
                item: item,
                message: "mutation callback went wrong"
            };
        }
    }


    // set(produce((state => {
    //     state.models[typeName].depth = 0
    // })));

    const newItem = { ...result.data[_mutationType + typeName] };
    await Cache.removeItem(`get${typeName}_${item.id}:id`);

    await Cache.setItem(`get${typeName}_${item.id}:id`, stringify(newItem),
        {
            expires: moment().add("15", "m").valueOf()
        }
    );


    return {
        item: { ...result.data[_mutationType + typeName] },
        statusCode: 200,
    };
}

export default mutation;

