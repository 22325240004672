/* eslint-disable no-template-curly-in-string */
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { green, orange } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AmplifyApiProvider from 'api/amplifyApi/AmplifyApiProvider/AmplifyApiProvider';
import Amplify, { Cache } from "aws-amplify";
import moment from "moment";
import "moment/locale/nl";
import { SnackbarProvider } from "notistack";
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { setLocale } from "yup";
import awsconfig from "./aws-exports";
import "./index.css";
import "./styles.css";
import './wdyr';



moment.locale("nl");

setLocale({
    mixed: {
        required: "Het veld is verplicht",
        number: "moet number zijn",
        string: "required",
        typeError: "test",
        notType: function notType(_ref) {
            switch (_ref.type) {
                case "number":
                    return "Moet een nummer zijn";
                case "object":
                    return "Type is niet geldig";
                case "string":
                    return "Moet een string zijn";
                default:
                    return "Type is niet geldig";
            }
        },
    },
    number: {
        typeError: "moet nummer zijn",
        type: "moet nummer zijn",
        min: "Nummer moet minimaal ${min} zijn",
        max: "Nummer moet maximaal ${min} zijn",
    },
    string: {
        typeError: "moet nummer zijn",
        type: "moet nummer zijn",
        email: "moet een geldig email address zijn",
    },
});

let theme = createTheme(
    {
        breakpoints: {
            values: {
              xs: 0,
              sm: 600,
              md: 900,
              lg: 1200,
              xl: 1536,
              xxl: 2000,
            },
          },
    }
)
theme = createTheme(theme, {
    palette: {
        primary: {
            light: green[300],
            main: green[500],
            dark: green[700],
            contrastText: "#fff",
        },
        secondary: {
            light: orange[300],
            main: orange[500],
            dark: orange[700],
            contrastText: "#fff",
        },
        // Used by `getContrastText()` to maximize the contrast between the background and
        // the text.
        contrastThreshold: 3,
        // Used to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.3,

    },
})




const cacheConfig = {
    capacityInBytes: 5000000,
    itemMaxSize: 1000000,
    defaultPriority: 4
    // ...
};

Cache.configure(cacheConfig);
Amplify.configure(awsconfig);


const LazyAuthenticator = lazy(() =>
    import("./codestorage_cms_V4/authentication/CommonAuth/CommonAuthenticator")
);


ReactDOM.render(

    <ThemeProvider theme={theme}>
        <LocalizationProvider
            dateAdapter={AdapterMoment}
        >
            <SnackbarProvider
            >
                <AmplifyApiProvider>
                    <Suspense fallback={<div />}>
                        <LazyAuthenticator />
                    </Suspense>
                </AmplifyApiProvider>


            </SnackbarProvider>

        </LocalizationProvider>
    </ThemeProvider>
    ,
    document.getElementById("root")
);

