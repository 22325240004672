import updateCacheAndState from "../model/updateCacheAndState";
import q_get from "../query/q_get";
import mutation from "./mutation";
import produce from "immer";

export const m_create = async ({ item, fetchConnections, set, get, typeName }) => {
    const result = await mutation(
        {
            mutationType: "create",
            item,
            set, get, typeName,
        }
    );
    if (result.statusCode === 200) {
      
        const res = await q_get({ id: result.item.id, fetchConnections, forceRefetch: true, connections: get().models[typeName].connections, typeName, get });
        updateCacheAndState({ item: { ...item, ...res }, mutationType: "create", set, get, typeName });

        set(produce((state => {
            state.messages = [{
                message: "Successvol aangemaakt",
                variant: "success"
            }]
        })));

        return {
            ...result,
            item: {
                ...item,
                ...res,
            }
        }

    }else{
        set(produce((state => {
            state.messages = [{
                message: "Aanmaken mislukt :(",
                variant: "error"
            }]
        })));
    }


      
    return result
}

export default m_create