import { Auth } from 'aws-amplify';
import Lambda from 'aws-sdk/clients/lambda';
import awsmobile from '../../../aws-exports';




export const invokeLambda = (functionName, variables) => {
    return new Promise((resolve, reject) => {
        Auth.currentCredentials()
            .then((credentials) => {
                const lambda = new Lambda({
                    credentials: Auth.essentialCredentials(credentials),
                    region: 'eu-west-1'
                });


                const params = {
                    FunctionName: `${functionName}-${process.env.REACT_APP_BRANCH_ENV}`, /* required */
                    InvocationType: 'RequestResponse',
                    LogType: 'Tail', // Can be set to Tail if execution log is needed in response for debug
                    Payload: Buffer.from(JSON.stringify( 
                        { 
                            ...variables,
                            userPoolId: awsmobile.aws_user_pools_id
                        }
                        
                        )) // Parameters of the lambda itself expected as Base64 String
                };

                lambda.invoke(params, (err, data) => {
                    if (Object.prototype.toString.call(data) === '[object Null]') {
                        reject(err + 'Er is iets fout gegaan')
                    } else {
                        resolve(data);
                    }


                });
            });
    })
}

export default invokeLambda;