export const awsPredicates = (filter, value, value2) => {
    switch (filter) {
        case "eq":
            return value === value2
        default:
            return value === value2
    }
}

export const firstCharToLowerCase = (string) => {
    return string.charAt(0).toLocaleLowerCase() + string.slice(1)
}

export function decycle(obj, stack = []) {
    if (!obj || typeof obj !== 'object')
        return obj;

    if (stack.includes(obj))
        return null;

    let s = stack.concat([obj]);

    return Array.isArray(obj)
        ? obj.map(x => decycle(x, s))
        : Object.fromEntries(
            Object.entries(obj)
                .map(([k, v]) => [k, decycle(v, s)]));
}


export const getCacheName = (typeName, operationName, variables) => {
    return JSON.stringify({
        typeName,
        operationName,
        variables,
    })
}

export const getQueryName = (operationName, variables) => {
    let primary;
    let secondary;



    const variableKeys = variables && Object.keys(variables);
    if (!operationName.includes("list")) {
        variableKeys.forEach((variableKey, index) => {
            if (index === 0) {
                primary = `${variables[variableKey]}`;
            }
            if (index === 1) {
                if (variables[variableKey]) {
                    const predicateKey = Object.keys(variables[variableKey])[0]
                    secondary = `${variableKey}.${predicateKey}:${variables[variableKey][predicateKey]}`
                }

            }

        })

        //QUERYNAME eventByLocationId[eventLocationId,monthYear:f65801cb-4d72-4a7b-b641-021d60d567d6,monthYear.eq:11/2021]
        return secondary ? `${operationName}[${primary},${secondary}]` : `${operationName}[${primary}]`

    } else {
        return "listAll"
    }
    //
}

export const addOrUpdateItemToArray = (array, item, mutationType) => {
    let newArray = array ? [...array]  :[];

    const findIndex = newArray.findIndex((newArrayItem => newArrayItem.id === item.id));


    if (mutationType === "create") {
        newArray.push(item);

    }
    if (findIndex > -1 && mutationType === "update") {
        newArray[findIndex] = item;
    }

    if (findIndex > -1 && mutationType === "delete") {
        newArray.splice(findIndex, 1);
    }




    return newArray;

}

export const formatConnections = (connections, item, mutationItem, typeName) => {
    connections && Object.keys(connections).forEach((connectionKey) => {
        const connection = connections[connectionKey];
        if (connection.relation === "belongsToMany" || connection.relation === "belongsTo" || connection.relation === "hasOne") {
            const connectionName = firstCharToLowerCase(connection.typeName);

            if (item && item[connectionName] && item[connectionName].id) {
                mutationItem[`${firstCharToLowerCase(typeName)}${connection.typeName}Id`] = item[connectionName] && item[connectionName].id;
            } else {
                mutationItem[`${firstCharToLowerCase(typeName)}${connection.typeName}Id`] = item[`${firstCharToLowerCase(typeName)}${connection.typeName}Id`];
            }

        }
    });

    return mutationItem;
}